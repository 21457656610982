/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:43:49 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-03 15:06:34
 */
<template>
  <div class="practice_details">
    <Banner></Banner>
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
    </div>
    <div class="top">
      <div class="width_1200">
        <div class="top_cont">
          <img v-lazy="practice.FileImg" class="tc_left" alt />
          <div class="tc_right">
            <el-row class="glkc" type="flex" align="middle">
              <el-col :span="24">试卷名称：{{ practice.Name }}</el-col>
            </el-row>
            <el-row class="glkc" type="flex" align="middle">
              <el-col :span="24"
                >试卷类型：{{
                  practice.PaperType == 0
                    ? "模拟试卷"
                    : practice.PaperType == 1
                    ? "历年真题"
                    : "专项练习"
                }}</el-col
              >
            </el-row>
            <!-- <el-row class="glkc" type="flex" align="middle">
              <el-col :span="5">是否支持试做：{{practice.IsAttempt == 1 ? '是' : '否'}}</el-col>
              <el-col :span="5">试做题数：{{practice.AttemptNumber}}</el-col>
            </el-row> -->
            <el-row class="glkc" type="flex" align="middle">
              <el-col :span="24">
                试卷价格：
                <span class="c_1288F4" v-if="practice.Category == 2">免费</span>
                <span
                  class="c_F40707"
                  v-else-if="practice.IsSale != 1 && practice.Category == 0"
                  >课程专属</span
                >
                <span
                  class="c_F40707"
                  v-else-if="practice.IsSale != 1 && practice.Category == 1"
                  >VIP专属</span
                >
                <span class="c_F40707" v-else-if="practice.IsSale == 1"
                  >￥{{ practice.Price | priceToFixed }}</span
                >
              </el-col>
            </el-row>
            <el-row class="glkc" type="flex" align="middle">
              <el-col :span="24">
                <el-button
                  @click="make()"
                  class="make"
                  v-if="practice.StudentIsBuy == 1"
                  >进入做题</el-button
                >
                <el-button
                  @click="joinShop()"
                  class="join"
                  v-if="
                    practice.StudentIsBuy == 0 &&
                    practice.IsSale == 1 &&
                    practice.Category != 2
                  "
                  >加入购课车</el-button
                >
                <el-button
                  @click="buy()"
                  class="buy"
                  v-if="
                    practice.StudentIsBuy == 0 &&
                    practice.IsSale == 1 &&
                    practice.Category != 2
                  "
                  >立即购买</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="cont">
          <div class="title">试卷介绍</div>
          <div class="detail">
            <div v-if="practice.Remark">{{ practice.Remark }}</div>
            <div v-else>
              <no-data></no-data>
            </div>
          </div>
        </div>
      </div>
    </div>
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
  </div>
</template>

<script>
import Banner from "./components/banner";
import BreadCrumb from "@/components/breadcrumb.vue";
import camera from "@/components/canvasCamera.vue";
import { questionBankQueryPaperDetail } from "@/api/questionBank";
import { studentCompareFace } from "@/api/api";
import { orderSaveCourseCar } from "@/api/order";
import noData from "@/components/noData";

export default {
  data () {
    return {
      practice: {},
      questionDetails: {},
      dialogVisible: false,
      jump: {},
      user: {},
    };
  },
  components: {
    Banner,
    BreadCrumb,
    camera,
    noData,
  },
  created () {
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.init();
  },
  mounted () { },
  methods: {
    getClose () {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace (flag) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        this.$router.push({ path: this.jump.url, query: this.jump.ob });
      } else {
        this.$message.error("识别失败");
        this.dialogVisible = false;
      }
    },
    //进入做题
    make () {
      let ob = {
        Id: this.questionDetails.Id,
        Name: this.practice.Name,
        UserID: this.user.Id,
      };
      this.jump.url = "/onlineQuestionBank/answer";
      this.jump.ob = ob;
      // this.$router.push({ path: this.jump.url, query: this.jump.ob });
      if (this.practice.Category == 2) {
        this.$router.push({ path: this.jump.url, query: this.jump.ob });
      } else {
        this.dialogVisible = true;
      }
    },
    // 加入购课车
    async joinShop () {
      let parm = {
        goodsId: this.questionDetails.Id,
        isVip: 0, //
        quantity: 1,
        GoodsCategory: 2, //0课程1资料2题库
      };
      const res = await orderSaveCourseCar(parm);
      if (res.success === true) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    //立即购买
    buy () {
      var userInfo = JSON.parse(this.$store.state.userInfo);
      if (!userInfo.IsIdentificate) {
        this.$confirm('你还没实名认证不能购买课程?', '提示', {
          confirmButtonText: '去实名',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push("/personal/personalData");
        }).catch(() => {

        });
        return false;
      }
      let obj = {
        IsVip: "0", //是否Vip（1是0否
        GoodsCategory: 2, //商品类型（0课程1资料2题库）
        Immediately: "1",
      };
      obj = { ...this.practice, ...obj };
      this.$router.push({
        path: "/onlineQuestionBank/order",
        query: { data: JSON.stringify([obj]) },
      });
    },
    async init () {
      this.questionDetails = this.$route.query;
      const res = await questionBankQueryPaperDetail(this.questionDetails.Id);
      if (res.success === true) {
        this.practice = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.practice_details {
  .top {
    min-width: 1200px;
    .top_cont {
      background-color: #ffffff;
      padding: 40px 30px;
      display: flex;
      .tc_left {
        width: 175px;
        height: 200px;
        margin-right: 40px;
      }
      .tc_right {
        flex: 1;
        color: #333333;
        width: 960px;
        .title {
          line-height: 58px;
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
        }
        .glkc {
          font-size: 14px;
          margin-top: 16px;
          .el-col {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .cont {
    .title {
      line-height: 60px;
      background-color: #fff;
      padding: 0 30px;
      margin: 10px 0;
      overflow: hidden;
      font-size: 16px;
      margin-bottom: 2px;
      color: #676767;
      position: relative;
      padding-left: 40px;
    }
    .title:after {
      position: absolute;
      width: 2px;
      height: 20px;
      background: #3c81f9;
      left: 30px;
      top: 20px;
      content: "";
    }
    .detail {
      line-height: 150%;
      background-color: #fff;
      padding: 10px 30px;
      // text-indent: 12px;
      min-height: 200px;
      margin-bottom: 50px;
    }
    .noData {
      padding-bottom: 100px !important;
    }
  }
}
</style>