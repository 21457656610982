var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"practice_details"},[_c('Banner'),_c('div',{staticClass:"width_1200"},[_c('div',{staticClass:"bread"},[_c('bread-crumb')],1)]),_c('div',{staticClass:"top"},[_c('div',{staticClass:"width_1200"},[_c('div',{staticClass:"top_cont"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.practice.FileImg),expression:"practice.FileImg"}],staticClass:"tc_left",attrs:{"alt":""}}),_c('div',{staticClass:"tc_right"},[_c('el-row',{staticClass:"glkc",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":24}},[_vm._v("试卷名称："+_vm._s(_vm.practice.Name))])],1),_c('el-row',{staticClass:"glkc",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":24}},[_vm._v("试卷类型："+_vm._s(_vm.practice.PaperType == 0 ? "模拟试卷" : _vm.practice.PaperType == 1 ? "历年真题" : "专项练习"))])],1),_c('el-row',{staticClass:"glkc",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":24}},[_vm._v(" 试卷价格： "),(_vm.practice.Category == 2)?_c('span',{staticClass:"c_1288F4"},[_vm._v("免费")]):(_vm.practice.IsSale != 1 && _vm.practice.Category == 0)?_c('span',{staticClass:"c_F40707"},[_vm._v("课程专属")]):(_vm.practice.IsSale != 1 && _vm.practice.Category == 1)?_c('span',{staticClass:"c_F40707"},[_vm._v("VIP专属")]):(_vm.practice.IsSale == 1)?_c('span',{staticClass:"c_F40707"},[_vm._v("￥"+_vm._s(_vm._f("priceToFixed")(_vm.practice.Price)))]):_vm._e()])],1),_c('el-row',{staticClass:"glkc",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":24}},[(_vm.practice.StudentIsBuy == 1)?_c('el-button',{staticClass:"make",on:{"click":function($event){return _vm.make()}}},[_vm._v("进入做题")]):_vm._e(),(
                  _vm.practice.StudentIsBuy == 0 &&
                  _vm.practice.IsSale == 1 &&
                  _vm.practice.Category != 2
                )?_c('el-button',{staticClass:"join",on:{"click":function($event){return _vm.joinShop()}}},[_vm._v("加入购课车")]):_vm._e(),(
                  _vm.practice.StudentIsBuy == 0 &&
                  _vm.practice.IsSale == 1 &&
                  _vm.practice.Category != 2
                )?_c('el-button',{staticClass:"buy",on:{"click":function($event){return _vm.buy()}}},[_vm._v("立即购买")]):_vm._e()],1)],1)],1)]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"title"},[_vm._v("试卷介绍")]),_c('div',{staticClass:"detail"},[(_vm.practice.Remark)?_c('div',[_vm._v(_vm._s(_vm.practice.Remark))]):_c('div',[_c('no-data')],1)])])])]),(_vm.dialogVisible)?_c('camera',{attrs:{"dialogVisible":_vm.dialogVisible},on:{"close":function($event){return _vm.getClose()},"studentface":_vm.postStudentCompareFace}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }